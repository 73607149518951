import Stack from "@mui/material/Stack";
import React, { FC } from "react";
import Avatar from "./Avatar";
import { Paragraph } from "../theme/text-variants";
import { colors } from "../theme/colors";
import { colorTokens } from "../theme/color-tokens";
import { styled } from "@mui/material";
import ShopBannerIconSm from "@/img/shop-banner-sm.svg";
import ShopBannerIconLg from "@/img/shop-banner-lg.svg";

interface UserTagProps {
  label?: string;
  color?: "default" | "light" | "dark";
  size?: "sm" | "lg";
  variant?: "seller" | "store";
  imgSrc?: string;
  onClick?: () => void;
}

const UserTag: FC<UserTagProps> = ({ label, color = "default", size = "lg", variant = "seller", imgSrc,onClick }) => {
  const isSmall = size === "sm";
  const isDark = color === "dark";
//   const isLight = color === "light";

  return (
    <Container size={size} color={color} onClick={onClick}>
      <Stack position="relative">
        {variant === "store" && (
          <Stack position="absolute" top={0} left="-4px" zIndex={6000}>
            {isSmall ? <ShopBannerIconSm /> : <ShopBannerIconLg />}
          </Stack>
        )}
        <Avatar src={imgSrc} size={isSmall ? "xs" : "sm"} />
      </Stack>
      {label && (
        <Paragraph size="xs" color={isDark ? colors.white : colors.black} fontWeight="bold">
          {label}
        </Paragraph>
      )}
    </Container>
  );
};

export default UserTag;

const Container = styled(Stack)<UserTagProps>(({ size, color }) => ({
  width: "fit-content",
  position: "relative",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: colorTokens.backgroundOpaque,
  gap: "4px",
  borderRadius: size === "sm" ? "12px 8px 8px 12px" : "16px 8px 8px 16px",
  // backdropFilter: "blur(2px)",
  paddingRight: "4px",
  ...(color === "light" && { backgroundColor: colorTokens.backgroundSelected }),
  ...(color === "dark" && { backgroundColor: colorTokens.backgroundOverlay }),
}));
