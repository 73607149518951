import React, { FC, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardMedia, Typography, Stack, Chip, Box } from '@mui/material';
import { Paragraph } from '../../theme/text-variants';
import StoreFrontIcon from '../../img/storefrontpurple.svg';
import { colors } from '../../theme/colors';
import { colorTokens } from '../../theme/color-tokens';
import HostImage from '../../img/Host 1.png';
import LivesIcon from '../../img/icons/smart_display.svg';
import FollowersIcon from '../../img/icons/group (1).svg';
import ProductsIcon from '../../img/icons/package_2.svg';
import { order } from 'styled-system';

interface HostDetailsCardProps {
  hostName: string;
  categories: any[];
  profileImage?: string;
  ordersCount?: number;
  followersCount?: number;

  livesCount?: number;
  onClick?: () => void;
}

const HostDetailsCard: FC<HostDetailsCardProps> = ({
  categories,
  profileImage,
  hostName,
  onClick,
  ordersCount,
  livesCount,
  followersCount
}) => {
  const filteredCategories = categories.filter((category): category is string => category !== undefined);

  return (
    <HostCard onClick={onClick}>
      <Box>
        <Stack direction="row">
          <ProfileContainer>
            {<img src={profileImage ? profileImage : HostImage.src} alt={hostName} /> }
          </ProfileContainer>
          <Stack direction="column" width={'100%'}>
            <HostNameContainer width={'100%'}>
              {/* <StoreFrontIcon /> */}
              <Paragraph size="md" fontWeight="bold" color={colors.white} noWrap>
              {hostName?.length! > 16 ? hostName?.slice(0, 19 - 3) + '...' : hostName}
              </Paragraph>
            </HostNameContainer>
            <Box padding={'8px'} gap={'8px'} display={'flex'} flexDirection={'column'}>
              <Box display={'flex'} flexDirection={'row'} gap={'8px'} alignItems={'center'}>
                <LivesIcon />
                <Paragraph size="xs" color={colorTokens.textDefault} noWrap>
                  {livesCount} Lives
                </Paragraph>
              </Box>

              <Box display={'flex'} flexDirection={'row'} gap={'8px'} alignItems={'center'}>
                <FollowersIcon />
                <Paragraph size="xs" color={colorTokens.textDefault} noWrap>
                  {followersCount}k Followers
                </Paragraph>
              </Box>

              <Box display={'flex'} flexDirection={'row'} gap={'8px'} alignItems={'center'}>
                <ProductsIcon />
                <Paragraph size="xs" color={colorTokens.textDefault} noWrap>
                  {ordersCount} Products Sold
                </Paragraph>
              </Box>
            </Box>

            <CategoriesStack direction="row" spacing={1}>
              {filteredCategories.map((category) => (
                <CategoryChip key={category} label={category} variant="filled" size="small" />
              ))}
            </CategoriesStack>

            <ScrollableDescription >
              <Paragraph size="xs" color={colorTokens.textDefault}>
                {/* //code for bio */}
                
                </Paragraph>
            </ScrollableDescription>
          </Stack>
        </Stack>
      </Box>
    </HostCard>
  );
};

export default HostDetailsCard;

const HostCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
  overflow: 'hidden',
  position: 'relative',
  backgroundColor: colors.white
}));

const CategoryChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  fontWeight: 'bold',
  backgroundColor: `${colors.gray100}`,
  color: `${colorTokens.textSubtle}`,
  margin: theme.spacing(0, 0.5),
  '&:first-of-type': {
    marginLeft: 0
  }
}));

const CategoriesStack = styled(Stack)({
  display: 'flex',
  width: '50%',
  padding: '0 8px',
  overflowX: 'auto',
  overflowY: 'hidden',
  flexWrap: 'nowrap',

  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  '@media (max-width: 600px)': {
    width: '172px'
  }
});

const HostNameContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  height: '40px',
  gap: '4px',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: '8px',
  backgroundColor: colors.purple500
});

const ProfileContainer = styled(Box)({
  width: '170px',
  height: '240px',
  flexShrink: 0,
  '& img': {
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }
});

const ScrollableDescription = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  padding: '4px 0px 32px 8px',
  width: '50%',
  alignItems: 'center',
  flexWrap: 'wrap',
  maxHeight: '74px',
  overflowY: 'auto',
  textAlign: 'justify',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '@media (max-width: 600px)': {
    width: '172px'
  }
});
